




































































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject, Prop } from "vue-property-decorator";
import { TrainingGroundEventViewmodel } from "../viewmodels/training-ground-event-viewmodel";

@Observer
@Component({
  components: {
    SelectLockPeriod: () => import("../components/select-lock-period.vue"),
    StakeResult: () => import("../components/stake-result.vue"),
  },
})
export default class IncreaseUnlockTime extends Vue {
  @Inject() vmEvent!: TrainingGroundEventViewmodel;

  isValidated = false;
}
